/* HCS2Frame Component Styles */

/* HCS2 Special Title Bar */
.hcs2-title-bar {
  display: flex;
  width: 100%;
  background: linear-gradient(90deg, #1a1a1a, #2c2c2c);
  border-radius: 4px;
  margin-bottom: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  user-select: none;
  overflow: hidden;
  position: relative;
}

.hcs2-title-bar:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  background: linear-gradient(90deg, #252525, #333333);
}

.hcs2-title-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 24px;
}

.hcs2-title-text {
  display: flex;
  flex-direction: column;
  flex: 1;
}

/* Import League Spartan font */
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@700&display=swap');

.hcs2-main-title {
  color: white;
  font-family: 'League Spartan', sans-serif !important;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin-bottom: 4px !important;
  font-size: 1.25rem !important;
}

.section-number {
  display: inline-block;
  font-family: 'League Spartan', sans-serif !important;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.75);
  margin-right: 8px;
  font-size: 0.9rem;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 2px 6px;
  border-radius: 4px;
  vertical-align: middle;
}

.hcs2-subtitle {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem !important;
  font-weight: 400;
}

.hcs2-dropdown-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  color: white;
  transition: all 0.3s ease;
}

.hcs2-title-bar:hover .hcs2-dropdown-indicator {
  background-color: rgba(255, 255, 255, 0.15);
}

/* Dropdown Content */
.hcs2-dropdown-content {
  max-height: 0;
  animation: slideDown 0.3s forwards;
  overflow: hidden;
  width: 100%;
}

@keyframes slideDown {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 900px;
    opacity: 1;
  }
}

/* Mini HCS2 Title Bar Styles */
.mini-hcs2-titlebar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
}

.mini-hcs2-titlebar:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background: linear-gradient(90deg, #fff 0%, rgba(255,255,255,0.5) 100%);
  transition: width 0.2s ease;
}

.mini-hcs2-titlebar:hover:after {
  width: 100%;
}

.mini-hcs2-titlebar .MuiSvgIcon-root {
  transition: transform 0.3s ease;
}

.mini-hcs2-titlebar:hover .MuiSvgIcon-root {
  transform: translateY(2px);
}

.hcs2-frame-container {
  width: 1280px !important; /* Fixed width for consistency */
  max-width: 1280px !important; /* Standard width matching HEXTRA design */
  min-width: 1280px !important; /* Minimum width matches maximum for consistent display */
  margin: 0 auto;
  overflow-x: visible; /* No scrolling within container - parent handles scrolling */
  overflow-y: hidden;
  background-color: var(--background-paper);
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  position: relative; /* Required for absolute positioning of children */
}

.hcs2-frame-wrapper {
  width: 1280px !important; /* Fixed width for consistency */
  max-width: 1280px !important; /* Standard width matching HEXTRA design */
  min-width: 1280px !important; /* Minimum width matches maximum for consistent display */
  margin: 0 auto;
  overflow: hidden;
}

/* Responsive adjustments - fixed width approach */
@media (max-width: 1300px) {
  .hcs2-frame-container,
  .hcs2-frame-wrapper {
    width: 1280px; /* Maintain fixed width on smaller screens with parent container scrolling */
  }
}

/* Focus Management */
iframe:focus-visible {
  outline: 2px solid #007FFF;
  border-radius: 4px;
}

/* Make the actual iframe sit below the overlay */
.hcs2-iframe {
  position: relative;
  z-index: 1; /* Below the shield */
}

/* Mobile enhancements */
@media (max-width: 768px) {
  .hcs2-frame-container {
    border-radius: 0; /* Remove border radius on mobile */
    box-shadow: none;
  }
}
