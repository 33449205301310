:root {
    --bg-primary: #ffffff;
    --text-primary: rgb(20, 20, 20);
    --text-secondary: #666666;
    --button-bg: rgb(20, 20, 20);
    --button-text: #ffffff;
    --button-hover: #333333;
    --border-color: rgb(20, 20, 20);
    --primary-color: rgb(20, 20, 20);
    --primary-hover: #333333;
    --disabled-bg: #e0e0e0;
    --disabled-text: #888888;
    --hover-bg: #f5f5f5;
    --glow-color: #FED141;
    --glow-subtle: rgba(254, 209, 65, 0.3);
    --bg-secondary: #f0f0f0;
    --element-bg: #ffffff;
    --accent-color: #224D8F;
    --accent-secondary: #D50032;
    --gradient-primary: linear-gradient(45deg, #1565C0 30%, #42A5F5 90%);
    --gradient-secondary: linear-gradient(45deg, #C62828 30%, #EF5350 90%);
    --box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

:root[data-theme="light"] {
    --bg-primary: #ffffff;
    --text-primary: rgb(20, 20, 20);
    --text-secondary: #666666;
    --button-bg: rgb(20, 20, 20);
    --button-text: #ffffff;
    --button-hover: #333333;
    --border-color: rgb(20, 20, 20);
    --primary-color: rgb(20, 20, 20);
    --primary-hover: #333333;
    --disabled-bg: #e0e0e0;
    --disabled-text: #888888;
    --hover-bg: #f5f5f5;
    --glow-color: #FED141;
    --glow-subtle: rgba(254, 209, 65, 0.3);
    --bg-secondary: #f0f0f0;
    --element-bg: #ffffff;
    --accent-color: #224D8F;
    --accent-secondary: #D50032;
    --gradient-primary: linear-gradient(45deg, #1565C0 30%, #42A5F5 90%);
    --gradient-secondary: linear-gradient(45deg, #C62828 30%, #EF5350 90%);
    --box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

:root[data-theme="dark"] {
    --bg-primary: rgb(20, 20, 20);
    --text-primary: #ffffff;
    --text-secondary: #999999;
    --button-bg: #ffffff;
    --button-text: rgb(20, 20, 20);
    --button-hover: #cccccc;
    --border-color: #ffffff;
    --primary-color: #ffffff;
    --primary-hover: #cccccc;
    --disabled-bg: #333333;
    --disabled-text: #666666;
    --hover-bg: #333333;
    --glow-color: #FED141;
    --glow-subtle: rgba(254, 209, 65, 0.3);
    --bg-secondary: #2a2a2a;
    --element-bg: #2a2a2a;
    --accent-color: #4f7ecc;
    --accent-secondary: #ff5252;
    --gradient-primary: linear-gradient(45deg, #42A5F5 30%, #90CAF9 90%);
    --gradient-secondary: linear-gradient(45deg, #EF5350 30%, #FFCDD2 90%);
    --box-shadow: 0 2px 8px rgba(0,0,0,0.4);
}

/* Base styles */
html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    min-height: 100vh;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

body {
    background-color: var(--bg-primary);
    color: var(--text-primary);
    transition: background-color 0.3s, color 0.3s;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: stretch;
    justify-content: flex-start;
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
}

/* Banner styles */
.app-banner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1100;
    margin: 0;
    padding: 0;
}

.banner-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 8px;
    padding-bottom: 16px;
    padding-left: 8px;
    padding-right: 8px;
}

.button {
    background-color: var(--button-bg);
    color: var(--button-text);
    border: none;
    border-radius: 4px;
    transition: background-color 0.2s;
    font-family: 'Inter', sans-serif;
}

.button:hover {
    background-color: var(--button-hover);
}

/* Theme toggle button */
#themeToggle {
    position: fixed;
    top: 1rem;
    right: 1rem;
    padding: 0.5rem;
    border-radius: 50%;
    border: none;
    background: var(--bg-primary);
    color: var(--text-primary);
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

#themeToggle:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
